.header-action {
    // position: fixed;
    // z-index: 2;
    background: $black;
    padding: 8px 15px;
    // height: $topbar-height;
    .custom-vr {
        margin: 10px 25px 6px;
        height: 38px;
        opacity: 0.7;
    }
    .form-select {
        background-color: black;
        color: $white;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    }
    .custom-select {
        select,
        option {
            min-width: auto;
        }
    }
}
