:root {
    --bs-blue: #2c8ef8;
    --bs-indigo: #727cf5;
    --bs-purple: #6b5eae;
    --bs-pink: #ff679b;
    --bs-red: #fa5c7c;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffbc00;
    --bs-green: #0acf97;
    --bs-teal: #02a8b5;
    --bs-cyan: #39afd1;
    --bs-white: #fff;
    --bs-gray: #98a6ad;
    --bs-gray-dark: #343a40;
    --bs-primary: #727cf5;
    --bs-secondary: #6c757d;
    --bs-success: #0acf97;
    --bs-info: #39afd1;
    --bs-warning: #ffbc00;
    --bs-danger: #fa5c7c;
    --bs-light: #eef2f7;
    --bs-dark: #313a46;
    --bs-font-sans-serif: 'inherit';
    --bs-font-monospace: 'inherit';
    --bs-primary-rgb: 0, 176, 171;
    --bs-secondary-rgb: 109, 121, 126;
    --bs-success-rgb: 41, 179, 74;
    --bs-info-rgb: 39, 155, 216;
    --bs-warning-rgb: 240, 168, 15;
    --bs-danger-rgb: 231, 112, 106;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}
