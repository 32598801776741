$tablet_view: 992/1200;

.exporttab {
    .custom-row {
        width: 256px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .exporttab {
        .custom-row {
            width: 300px * $tablet_view;
        }
    }
}

@media only screen and (max-width: 767px) {
    .exporttab {
        .custom-row {
            width: 100%;
        }
    }
}
