/* js tree height overflow scroll on content panel select new location modal
 * added custom class - jstree-custom-style
*/

.jstree-custom-style {
    .jstree {
        max-height: 400px;
        min-height: 400px;
        overflow-y: scroll;
        margin-bottom: 20px;
    }
}

/**
 * js tree spacing on top
 */
.jstree-container-ul {
    li:first-child {
        // padding-top: 10px;
        .jstree-children {
            li:first-child {
                padding-top: 0;
            }
        }
    }
}
//disabled jstree style
.jstree-default .jstree-disabled {
    pointer-events: none;
    // font-style: italic;
    opacity: 0.8;
    &.jstree-clicked {
        background-color: transparent;
    }
    > .jstree-icon {
        opacity: 1;
    }
    .glyphicon-text-background-cp-interaction-printable,
    .glyphicon-text-background-cp-interaction {
        opacity: 0.72;
    }
}
.jstree-default {
    > .jstree-no-dots .jstree-closed > .jstree-ocl {
        background-position: -4px -2px;
    }
    > .jstree-no-dots .jstree-open > .jstree-ocl {
        background-position: -36px -2px;
    }
    .jstree-anchor.jstree-clicked {
        color: $white !important;
    }
    .jstree-icon:empty {
        width: 25px;
        height: 30px !important;
        line-height: 30px !important;
    }
    .jstree-anchor {
        line-height: 30px;
        height: 30px;
    }
    .jstree-wholerow {
        height: 30px;
    }
    .jstree-wholerow-hovered {
        background: #e7f4f9;
    }
    .jstree-wholerow-clicked {
        background: #00afaa !important;
        color: #fff;
    }
}
.jstree-node {
    .jstree-open {
        > a {
            i.fa-fa-folder-palette-closed:before {
                background-image: url(../../../../images/icons/fa-fa-folder-palette-open.svg) !important;
                width: 17px;
                height: 17px;
            }
            i.fa-fa-folder-brand-closed:before {
                background-image: url(../../../../images/icons/fa-fa-folder-brand-open.svg) !important;
                width: 17px;
                height: 17px;
            }
            i.fa-folder:before {
                content: '\f07c';
            }
        }
    }
}
.loading-text {
    line-height: 30px;
}
.jstree-default .jstree-icon:empty {
    &.glyphicon-pdf-file,
    &.glyphicon-image,
    &.glyphicon-video,
    &.glyphicon-doc-file {
        height: 24px !important;
        line-height: 24px !important;
    }
}

// .jstree-default .jstree-anchor.jstree-clicked {
//     width: 100%;
//     z-index: 99;
// }
