//Analytics page - email block UI
.analytics-data {
    @include custom-filter-style(200px, 185px);
}
//edit record and setting - tag block UI
.edit-record-and-settings-page {
    @include custom-filter-style(115px, 170px);
}
.build-new-survey {
    @include custom-filter-style(115px, 170px);
}

/*custom-card-panel
**fixed height & scroll
*/
.digital-assets,
.content-Panel {
    .custom-card-height {
        height: 550px;
        @include custom-height-and-scroll(550px);
    }
}

// scroll on impact sort modal
.impact-sort-modal {
    @include custom-height-and-scroll(600px);
    ul {
        padding: 0;
        margin: 0;
    }
    [data-rbd-drag-handle-context-id='0'] {
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        touch-action: manipulation;
    }
    [data-rbd-drag-handle-context-id='0'] {
        cursor: -webkit-grab;
        cursor: grab;
        &[style] {
            cursor: -webkit-grabbing;
            cursor: grabbing;
            pointer-events: auto !important;
        }
    }
}

.analytics-dashboard-page {
    .card-header {
        border: 0;
        .align-items-center {
            justify-content: flex-end !important;
        }
    }
    .dropdown-card {
        .card-header {
            border-bottom: #dee2e6 1px solid;
            .align-items-center {
                justify-content: space-between !important;
            }
        }
    }
}
//hide iframe--> getting issue when changing a single code of line in browser..
#freshworks-container iframe {
    background-color: transparent !important;
}
iframe[style]:not(#launcher-frame, #lightbox-frame, #widget-frame, .wistia_embed) {
    display: none !important;
    &.video-block {
        display: block !important;
        width: inherit;
        height: inherit;
        background-color: $black;
    }
}
video {
    background-color: $black;
}
//Custom svg size
.view-sites-table,
.view-reports-table,
.review-and-approval-table-site,
.review-and-approval-table-report,
.report-data,
.view-portfolio-table {
    .custom-svg svg {
        width: 23px;
    }
    .custom-svg svg.open-in-new-tab-custom-icon {
        width: 19px;
    }
    .custom-svg svg.open-in-new-tab-custom-icon.lock {
        width: 22px;
    }
    // svg.open-in-new-tab-custom-icon {
    //     width: 19px;
    // }
}
.view-portfolio-table {
    .custom-svg svg.open-in-new-tab-custom-icon {
        width: 19px;
        margin-top: -5px;
    }
    .custom-svg.hover-info svg.open-in-new-tab-custom-icon:hover path {
        fill: #279bd8;
    }
}
.content-assets-panel-wrap .btn-group .action-icon.custom-svg svg.open-in-new-tab-custom-icon {
    width: 19px;
}
//scroll on donor tab
.donors-tab {
    @include custom-height-and-scroll(1000px);
    .border-bottom3 {
        border-bottom: 3px solid #dee2e6;
    }
    .checkbox {
        width: 3%;
    }
    .donor-list {
        width: 97%;
    }
}
//custom header UI
.custom-header {
    @include custom-header();
}
//Border removed from datepicker
.edit-record-and-settings-page {
    .react-datepicker__input-container {
        input.form-control-light {
            border: none;
        }
    }
    .custom-select {
        width: auto;
        min-width: 230px;
        max-width: 235px;
    }
}

.SiteBTD,
.ReportBTD {
    ul {
        padding: 0;
        margin: 0;
    }
}

html,
body {
    height: inherit !important;
}
.report-setting-block {
    hr.mb-4 {
        margin-bottom: 1.9rem !important;
    }
}
.responsive-case {
    .operational-bar {
        display: none !important;
    }
}
.responsive-page {
    background-color: #f3f9fb;
    height: 100vh;
    width: 100%;
    header {
        position: relative !important;
        &.header-action {
            height: 115px !important;
        }
    }
    .logo {
        width: 100%;
        padding: 22px;
        position: relative;
        img {
            width: 225px;
            height: auto;
        }
    }
    span.logo-lg {
        display: block !important;
    }
    .mdi-arrow-expand-all {
        font-size: 45px;
    }
}
// Fund deatils & impact page asset
.fund-details,
.impact-details {
    .asset-box {
        min-height: 200px;
        min-width: 200px;
        height: 200px;
        width: 200px;
        img {
            height: 200px;
            width: 200px;
            margin-left: 0;
            transform: inherit;
            -webkit-transform: inherit;
            max-height: 200px;
            max-width: 200px;
            margin-top: 0;
            object-fit: cover;
        }
    }
}

// User login page
body.authentication-bg.user-login-body {
    background-image: none;
    background-color: #e2e3e3;
    #rootHtml {
        padding-top: 0;
    }
}
.user-login {
    footer {
        display: none;
    }
    .footer-text {
        border: 1px solid $warning;
        color: #333333 !important;
        font-size: 14px;
    }
    .bg-warning {
        margin-top: -1px !important;
    }
    .login-text {
        color: #333333 !important;
    }
    .btn-primary {
        background-color: #0d6efd;
        border-color: #0d6efd;
        padding: 0.55rem 0.9rem 0.45rem;
        &:hover {
            background-color: #0b5ed7;
            border-color: #0a58ca;
        }
    }
}

.previewOnly {
    .seperate-slider-collapsable {
        display: none !important;
    }
}

// Freshdesk iframe should be visible
// #freshworks-container {
//     iframe[data-testid='launcher-frame'] {
//         display: block !important;
//         background-color: transparent !important;
//     }
// }

.FUND_IMPACT {
    .grid {
        .generic-text-style-2 {
            font-size: 14px;
            line-height: 21px;
            color: #444444;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0.08px;
        }
        label {
            color: #cc0000;
            display: block;
            font-weight: 700;
        }
    }
    .grid {
        // background: #eee;
        /* center */
        // margin: 0 auto;
        max-width: 830px;
        clear: left;
    }

    /* clearfix */
    .grid:after {
        content: '';
        display: block;
        clear: both;
    }

    /* ---- grid-item ---- */

    .grid-item {
        width: 330px;
        padding-right: 20px;
        // height: 160px;
        // background-color: brown;
        // float: left;
        // border: 2px solid #333;
        // border-color: hsla(0, 0%, 0%, 0.5);
        // border-radius: 5px;
        &:nth-of-type(odd) {
            // margin-right: 20px;
        }
    }
}
// * { box-sizing: border-box; }

// body { font-family: sans-serif; }

/* ---- grid ---- */
.recrop-asset-parent-wrapper {
    width: 202px;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}
.edit-profile-information {
    .custom-width-switch {
        min-width: 86px;
        width: auto;
    }
    .recrop-asset-parent-wrapper {
        width: 182px;
        .photo-upload {
            margin-bottom: 0;
        }
    }
    .tab-content .asset-box {
        min-height: 180px;
        min-width: 180px;
        height: 180px;
        width: 180px;
    }
}

@media only screen and (min-width: 992px) {
    .asset-upload-common-section,
    .vcard-section {
        .text-wrapper,
        .asset-wrapper {
            .left {
                width: 230px;
            }
            .right {
                width: 530px;
            }
        }
        .alt-text-wrapper {
            width: 1520px;
        }
    }
}
// Hiding change log icon once for all to uncomment the css
.change-log {
    // display: none !important;
    &.access-criteria-change-log {
        font-size: 1.5rem;
    }
    &.custom-position {
        position: absolute;
        top: 82px;
        left: 257px;
        outline: none !important;
        border: none !important;
        padding-left: 0;
        .icon-large {
            font-size: x-large;
        }
    }
    &.custom-position {
        //   opacity: 0.2; /* Start with opacity 0 */
        // animation: fadeIn 5s forwards; /* Apply animation to change opacity */
        &.apikey-setting {
            // left: 164px;
            left: unset;
            margin-left: 70px;
            &.custom-title-position {
                margin-left: 99px;
            }
        }
        &.analytics-setting {
            // left: 283px;
            left: unset;
            margin-left: 188px;
            &.custom-title-position {
                margin-left: 197px;
            }
        }
        &.site-setting {
            // left: 226px;
            left: unset;
            margin-left: 130px;
            &.custom-title-position {
                margin-left: 148px;
            }
        }
        &.report-setting {
            // left: 256px;
            left: unset;
            margin-left: 160px;
            &.custom-title-position {
                margin-left: 173px;
            }
        }
        &.group-setting {
            // left: 170px;
            left: unset;
            margin-left: 74px;
            &.custom-title-position {
                margin-left: 103px;
            }
        }
        &.brand-setting {
            // left: 248px;
            left: unset;
            margin-left: 152px;
            &.custom-title-position {
                margin-left: 167px;
            }
        }
        &.BTD {
            left: 15px;
            &.custom-title-position {
                left: 307px;
                margin-left: 8px;
            }
        }
        &.performance {
            // animation: performanceFadeIn 5s forwards; /* Apply animation to change opacity */
            // left: 297px;
            left: unset;
            margin-left: 201px;
            &.custom-title-position {
                margin-left: 208px;
            }
        }
        &.system-setting {
            // left: 258px;
            left: unset;
            margin-left: 163px;
            &.custom-title-position {
                margin-left: 176px;
            }
        }
        &.administrator-setting {
            // left: 252px;
            left: unset;
            margin-left: 157px;
            &.custom-title-position {
                margin-left: 171px;
            }
        }
        &.cloud-details {
            // left: 358px;
            left: unset;
            margin-left: 265px;
            &.custom-title-position {
                margin-left: 261px;
            }
        }
        &.user-setting {
            // left: 181px;
            left: unset;
            margin-left: 90px;
            &.custom-title-position {
                margin-left: 116px;
            }
        }
        &.data-schema-setting {
            // left: 273px;
            left: unset;
            margin-left: 182px;
            &.custom-title-position {
                margin-left: 192px;
            }
        }
        &.survey-setting {
            // left: 252px;
            left: unset;
            margin-left: 160px;
            &.custom-title-position {
                margin-left: 173px;
            }
        }
        &.record-setting {
            left: unset;
            margin-left: 161px;
            &.custom-title-position {
                margin-left: 175px;
            }
        }
    }
}

body[data-leftbar-compact-mode='fixed'] {
    .change-log.BTD {
        &.custom-title-position {
            left: 497px;
        }
    }
}
.page-title {
    .change-log {
        padding-left: 2px !important;
    }
}
.change-log-custom-style {
    display: inline-flex;
    .change-log {
        height: fit-content;
        font-size: 1.5rem;
        padding: 10px 10px;
    }
}
.custom-margin-btd {
    margin-top: 4rem !important;
}
.custom-style-btd {
    float: inherit !important;
    padding: 0 !important;
}
.horizontal-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
.tenant-soft-deleted-page {
    .nav-link {
        padding: 0.5rem 0.5rem;
    }
}
