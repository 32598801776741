// 
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  // float: right !important;
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none !important;
  }

  button[disabled] {
    color: $gray-500 !important;
  }
} 
/**Goto-Page-width
*goto-page-width
*used for increasing width
*/
.goto-page-width{
  width:75px;
}