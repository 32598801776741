.tox.tox-tinymce-inline .tox-editor-header {
    border-color: $white !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    box-shadow: 0px 2px 6px 0px rgb(51 62 68 / 28%) !important;
    margin-top: -2px;
    z-index: 17;
    width: 415px !important;
    .tox-toolbar__group {
        padding: 0;
        margin: 0;
        background-color: $white;
        &:before {
            content: 'Text: ';
            margin: 0 15px;
            color: #575a5d;
        }
        .tox-tbtn--select {
            margin: 0;
        }
        .tox-tbtn {
            margin: 0;
            border-radius: 0;
            width: 42px;
            height: 36px;
            &:hover {
                background-color: $primary;
                color: $white;
                svg {
                    fill: $white;
                }
            }
            &.tox-tbtn--disabled {
                background-color: #e2e3e3;
                color: #797c7f;
                svg {
                    fill: #797c7f;
                }
            }
            &:last-child {
                background-color: $white;
                cursor: auto;
                font-size: 14px;
                min-width: 53px;
                &:hover {
                    background-color: $white;
                }
                color: #008f8a;
            }
            svg {
                fill: #575a5d;
                // transform: scale(0.9);
            }
        }
    }
}
// Hiding extra field(title & open link in) on insert link modal of tinymce
.tox-dialog-wrap .tox-dialog[aria-modal='true'] .tox-dialog__body-content {
    .tox-form :nth-child(3),
    .tox-form :nth-child(4) {
        display: none;
    }
}
// Hide suggestions of tinymce insert link modal
.tox-dialog__popups {
    display: none !important;
}
.inner-positioner-header-panel-heading p,
.standard-template .inner-positioner-header-panel-heading p {
    margin: 0;
}
.mce-visual-caret,
p[data-mce-caret='after'] {
    display: none !important;
    margin: 0;
    min-height: 0;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
    box-shadow: none !important;
    border-bottom: 1px solid #e3e3e3 !important;
}
.tox.tox-tinymce-inline {
    z-index: 999;
}
.areaTinyInputEditor {
    position: relative;
    .text-limit {
        position: absolute;
        right: 25px;
        bottom: -14px;
    }
}
.default-greeting-message .areaTinyInputEditor .text-limit {
    bottom: 2px;
}
