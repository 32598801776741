//
// buttons.scss
//

// .button-list {
//     margin-bottom: 25px;
//     .btn {
//         margin-right: 20px;
//         border-radius: 0.3rem;
//     }
// }
.btn {
    .mdi {
        &:before {
            line-height: initial;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}
.asset-replacer.operational-bar {
    .aop-disabled {
        pointer-events: none;
        background-color: gray;
        display: none;
    }
}

// Button Shadows
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-shadow($value);
    }
}
