$tablet_view: 992/1200;
.slick-slide iframe.wistia_embed,
.NEWS_PARENT iframe.wistia_embed {
    padding-bottom: 20px;
}
[id='PROFILE'] iframe.wistia_embed {
    padding-bottom: 35px;
}
.custom-time-picker {
    border: 1px solid #dee2e6;
    width: 100px;
    margin-bottom: 5px;
    border-radius: 0.25rem;
    .custom-time-picker-input {
        width: 30px;
        border: none;
        text-align: center;
        color: #6d797e;
    }
}
.wistia-thumbnail {
    .configure {
        height: 155px;
    }
    .textOverlay {
        .invalid-feedback {
            display: none !important;
        }
        .optinal-text {
            color: #5656a5e0;
            font-size: 13px;
        }
    }
    .upload-image {
        color: #009691;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 0 !important;
    }
    [name='file'] {
        display: none;
    }
    .startTimeLabel,
    .endTimeLabel {
        width: 100px;
    }
    .startTime,
    .endTime {
        width: 370px;
    }
    .currentTimeLabel {
        width: 234px;
        margin-left: 8px;
    }
    .currentTime {
        width: 215px;
        padding-right: 0;
        input {
            width: 80px;
            margin-top: -8px;
        }
    }
    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    [name='showTextOnHover'] {
        margin-left: 0;
    }
    [for='showTextOnHover'] {
        margin-left: 5px;
    }
    .invalid-feedback {
        height: 12px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slick-slide iframe.wistia_embed,
    .NEWS_PARENT iframe.wistia_embed {
        padding-bottom: 20px * $tablet_view;
    }
    [id='PROFILE'] iframe.wistia_embed {
        padding-bottom: 35px * $tablet_view;
    }
}
@media only screen and (max-width: 991px) {
    .slick-slide iframe.wistia_embed,
    .NEWS_PARENT iframe.wistia_embed {
        padding-bottom: 10px;
    }
    [id='PROFILE'] iframe.wistia_embed {
        padding-bottom: 17px;
    }
}
