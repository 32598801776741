//custom shift button
.custom-shift-btn {
    .btn {
        padding: 0px;
        height: 30.8px;
        width: 45.33px;
        line-height: 1;
        span {
            font-size: 23px;
            align-items: center;
        }
    }
}

@each $color, $value in $theme-colors {
    .shadow-none.link-#{$color} {
        &:focus-visible {
            box-shadow: 0 0 0 0.2rem rgb(0 176 171 / 25%) !important;
        }
        &:focus {
            color: $link-color;
            text-decoration: none;
        }
        &:hover {
            color: $link-hover-color;
            text-decoration: underline !important;
        }
    }
}
.btn-link,
.btn {
    &:focus-visible {
        box-shadow: 0 0 0 0.2rem rgb(0 176 171 / 25%) !important;
    }
    &a {
        &:focus-visible {
            box-shadow: 0 0 0 0.2rem rgb(0 176 171 / 25%) !important;
        }
    }
}

.disabledRDTbutton {
    color: #00b0aa !important;
}
.forgot-password {
    margin-top: -20px;
    font-size: 0.75rem;
    &.shadow-none.link-muted {
        &:hover,
        &:focus {
            color: #858e91;
        }
    }
}
.login-text {
    font-size: 16px;
}
.login-or {
    span {
        background-color: #ffffff;
        position: relative;
        top: -14px;
        font-size: 16px;
        padding: 0px 10px;
    }
}
.btn.disabled-tooltip {
    pointer-events: stroke !important;
    color: #9ca7ab !important;
    cursor: default;
    opacity: 0.65 !important;
}
// cursor pointer event on account page Privacy & security page
.privacy-security {
    cursor: pointer;
}

// alignment of custom shift button
@media (min-width: 0px) and (max-width: 991.98px) {
    .custom-shift-btn-at-mob {
        width: 200px;
        margin: auto;
    }
    .custom-shift-btn-mob-four {
        width: 250px;
        margin: auto;
    }
    .custom-shift-btn-mob-five {
        width: 350px;
        margin: auto;
    }
}

//
//
//
.progtrckr {
    .progtrckr-doing,
    .progtrckr-done,
    .progtrckr-todo {
        &:before {
            border-radius: 50%;
            background-color: #6d797e;
            width: 20px;
            height: 20px;
            bottom: -67px;
            left: 0;
        }
    }
    .progtrckr-doing,
    .progtrckr-done {
        &:before {
            border: 2px solid #00b0ab;
            content: '\f0f62';
            display: inline-block;
            font: normal normal normal 24px/1 'Material Design Icons';
            font-size: inherit;
            text-rendering: auto;
            line-height: inherit;
            -webkit-font-smoothing: antialiased;
            color: #00b0ab;
            font-size: 14px;
            line-height: 17.5px;
            padding-right: 1px;
        }
        span {
            :before {
                content: '';
                position: absolute;
            }
        }
    }
    .progtrckr-todo {
        &:before {
            border: 2px solid #6d797e;
        }
    }
}

// custom svg css
.custom-svg {
    svg {
        width: 20px;
        path {
            fill: $gray-700;
        }
        &:hover {
            path {
                fill: #00b0ab;
            }
        }
    }
    &.text-danger {
        svg path {
            fill: $danger;
        }
    }
    &.text-success {
        svg path {
            fill: $success;
        }
    }
}

/*
* Edit Profile delete button on profile upload input
* button class profile-img-delete
*/
.fileinput-wrap {
    position: relative;
    .fileinput-remove-btn {
        position: absolute;
        right: 8px;
        top: 11px;
    }
    input[type='text'] {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
// Icons border issue fix
.icons-wrapper {
    button {
        border-radius: 0;
    }
}
// css for line height as zero
.lh-0 {
    line-height: 0;
}
/**
 * CSS for center align button which is right to the input
 */
.btn-after-input {
    margin-top: 11px;
}

/**
 * Button with right arrow
 * Add class in buttonElement - btn-with-right-arrow
 */
.btn-with-right-arrow {
    display: flex;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
    i {
        font-size: 20px;
    }
}

/**
 * 
 */
.content-assets-panel-wrap {
    .action-icon {
        span > span {
            padding-top: 4px;
            padding-bottom: 4px;
            margin-right: 0.1rem !important;
            margin-left: 0.1rem !important;
        }
    }
}

.btn-hover-outline-primary:hover {
    border-color: #008d89;
    color: #009691;
    background-color: transparent;
}
.btn-hover-outline-info:hover {
    color: #2184b8;
    background-color: transparent;
    border-color: #1f7cad;
}

.ovrture-score {
    span {
        i {
            top: 1.5px;
        }
    }
}

// Add css for align tooltip on content panel action icon
.content-assets-panel-wrap {
    .btn-group {
        .action-icon {
            span {
                span {
                    font-size: 21px;
                    .font-20 {
                        font-size: 21px !important;
                    }
                }
            }
            &.upload,
            &.download,
            &.delete {
                span > span {
                    padding-top: 5px;
                    padding-bottom: 3px;
                }
            }
            &.custom-svg {
                svg {
                    width: 21px;
                    margin-bottom: 4px;
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }
}
//Access to button UI
.access-to-button {
    .btn {
        width: 199px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

//Portfolio button style
.view-portfolio-table,
.view-reports-table,
.view-sites-table,
.basic-elements,
.edit-element-report-data {
    .custom-style {
        padding: 0.25rem 0.5rem;
        font-size: 0.8rem;
    }
}
button,
a {
    &.btn:disabled,
    &.btn.disabled {
        // font-style: italic;
    }
}
.focus-disabled {
    &.btn-primary:focus {
        color: $white;
        background-color: $primary;
        border-color: $primary;
    }
    &.btn-primary:hover {
        color: $white !important;
        background-color: #009691 !important;
        border-color: #008d89 !important;
    }
}
.btn-light:not(.dropdown-toggle) {
    &:hover {
        background-color: $primary;
        color: $white;
        border-color: $primary;
    }
}

.export-page {
    button:focus:not(:focus-visible):not(:hover) {
        &.btn-primary {
            background-color: $primary;
            border-color: $primary;
        }
        &.btn-info2 {
            background-color: $orange;
            border-color: $orange;
        }
    }
}

// Disabled button format
.btn-outline-primary:disabled:not(.action-icon),
.btn-outline-primary.disabled:not(.action-icon),
.btn-outline-danger:disabled:not(.action-icon),
.btn-outline-danger.disabled:not(.action-icon),
.btn-outline-info2:disabled:not(.action-icon),
.btn-outline-info2.disabled:not(.action-icon),
.btn-outline-info:disabled:not(.action-icon),
.btn-outline-info.disabled:not(.action-icon) {
    color: $secondary;
    border-color: $secondary;
}
.btn-primary:disabled:not(.action-icon),
.btn-primary.disabled:not(.action-icon),
.btn-danger:disabled:not(.action-icon),
.btn-danger.disabled:not(.action-icon),
.btn-info2:disabled:not(.action-icon),
.btn-info2.disabled:not(.action-icon),
.btn-info:disabled:not(.action-icon),
.btn-info.disabled:not(.action-icon) {
    color: #fff;
    background-color: $secondary;
    border-color: $secondary;
}
.btn-outline-primary.secondary-variant:disabled,
.btn-outline-primary.secondary-variant.disabled {
    color: $secondary;
    border-color: $secondary;
}
[class^='dripicons-']:before,
[class*=' dripicons-']:before {
    line-height: inherit;
}
table {
    [class^='dripicons-']:before,
    [class*=' dripicons-']:before {
        line-height: 1.7;
    }
}
// SHow tooltip for disabled button
.disable-show-tooltip {
    cursor: auto;
    opacity: 0.65;
    &:hover {
        color: #6d797e !important;
    }
}

// Tenant copy export import icons
.export-icon,
.import-icon {
    .mdi-file-export,
    .mdi-file-import {
        font-size: 30px;
    }
}
.table-responsive .icon-large {
    &.mdi-file-export,
    &.mdi-file-import {
        font-size: 25px;
    }
}
