//
// error.scss
//

.text-error {
    color: $primary;
    font-size: 5.25rem;
    line-height: 5.625rem;
}
.account-pages {
    &.error-page {
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 0 12px 0 !important;
    }
    .button-list {
        a {
            margin-right: 8px;
        }
    }
}
.under-construction {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
}

.under-construction-nav {
    max-height: 100px;
    float: left;
    width: 100%;
}

.custom-disabled {
    pointer-events: none;
    opacity: 0.65;
}
