//
// modal.scss
//

// Remove border from modal-content
.modal-content {
    border: 0;
}
// Title text within header
.modal-title {
    margin-top: 0;
}

// Modal full width
.modal-full-width {
    width: 95%;
    max-width: none;
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}

.visually-hidden-modal {
    visibility: hidden;
}

.visually-visible-modal {
    visibility: visible;
}

.modal.modal-fullsize {
    width: calc(100vw - 140px);
    height: calc(100vh - 140px);
    margin: 70px 70px 70px;
    overflow: hidden;
    .modal-dialog {
        max-width: none;
        margin: 0;
        .card {
            margin-bottom: 15px;
        }
        .card-body {
            padding: 0.5rem;
        }
        [role='table'] {
            max-height: calc(100vh - 361px) !important;
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}

.modal.selectFundReportWithReportUpdateOn [role='table'] {
    overflow: scroll;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width: 11px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

// Right Modal
.modal-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;
    transform: translate(25%, 0) !important;

    button.btn-close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
}

.modal {
    &.show {
        .modal-right,
        .modal-left {
            transform: translate(0, 0) !important;
        }
    }
}

// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Colored modal header
.modal-colored-header {
    color: $white;
    border-radius: 0;
    .btn-close {
        color: $white !important;
    }
}

// Full filled modals
.modal-filled {
    color: $white;
    .modal-header {
        background-color: rgba($white, 0.07);
    }
    .modal-header,
    .modal-footer {
        border: none;
    }
    .btn-close {
        color: $white !important;
    }
}

// Model
.modal-open {
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.modal-dialog:not(.preview) {
    .modal-content .modal-body p {
        margin-bottom: 1rem;
        &.text-limit {
            margin-bottom: 0;
        }
    }
}

.new-cp-modal {
    .glyphicon:before {
        margin: -4px 6px;
    }
}
.createNewEditableContentPanelModal {
    .glyphicon:before {
        margin-top: 5.5px;
        width: 60px;
        height: 60px;
    }
    .icon {
        width: 60px;
    }
    .paragraph-center {
        width: 89%;
        margin-left: 20px;
        display: flex;
        align-items: center;
        p {
            font-size: 12.5px;
            padding: 0;
            margin-bottom: 0 !important;
        }
        .text-orange {
            color: orange !important;
        }
    }
    .row {
        padding: 20px 1rem 0 1rem !important;
    }
    .form-floating {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
.createNewContentPanelModal {
    p {
        padding: 20px 0 0 15px !important;
        margin: 0 !important;
    }
    .row {
        padding: 15px 0 0 15px !important;
    }
    .form-check-label {
        display: flex;
        align-items: center;
        .glyphicon:before {
            margin-right: 5px;
        }
    }
    [name='contentPanelType'] {
        margin-top: 5px;
    }
}
.selectTemplateWithPreview {
    // .card {
    //     width: 370px;
    //     height: 400px;
    //     overflow: auto;
    // }
    p {
        padding: 15px 15px 0 !important;
    }
    .row {
        padding: 0 1rem 15px 1rem !important;
    }
}
.select-impact-data-fields {
    .card {
        margin: 0;
        .row {
            padding: 0;
        }
    }
    .link-fund-right-table .card .card-body {
        height: 625px;
    }
}
.multi-step.duplicate {
    .modal-steps {
        display: none;
    }
    h5.text-primary {
        padding: 15px 15px 0 15px;
        margin-bottom: 0 !important;
    }
    .modal-footer {
        display: none;
    }
    .footer-text {
        padding: 0 15px 15px 15px;
    }
}
.module-select {
    width: 196px;
    margin-left: 8px;
}
.cropper-main-container.disabled {
    pointer-events: none;
    opacity: 0.9;
}
