//
// reboot.scss
//

body {
    &.loading {
        visibility: hidden;
    }
}

button,
a {
    outline: none !important;
}

// Forms

label {
    font-weight: $font-weight-semibold;
}

// Address
address.address-lg {
    line-height: 24px;
}

b,
strong {
    font-weight: $font-weight-bold;
}
.grecaptcha-badge + iframe {
    display: block !important;
}
