.mfa {
    margin-top: 25px;
    &.profile-mfa-margin {
        margin-top: -15px;
    }
    .mfa-title {
        width: 370px;
        color: #00b0ab;
        font-weight: bold;
        font-size: 15px;
        text-transform: uppercase;
    }
    .datamap-title {
        width: 180px;
        color: #00b0ab;
        font-weight: bold;
        font-size: 15px;
        text-transform: uppercase;
    }
    .mfa-configuration-title {
        font-size: medium;
        margin-bottom: 1.5rem;
        font-weight: 600;
    }
    .mfa-content {
        ol {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    form {
        padding-left: 20px;
        padding-right: 20px;
        &.deviceRegistered {
            padding: 0;
        }
        // button {
        //     background-color: green !important;
        // }
    }
    &.manage {
        .mfa-title {
            width: 340px;
        }
    }
    .modal-footer {
        border: none !important;
        padding: 0;
    }
}
