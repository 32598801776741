#progress_circle_wrapper {
    #progress-circle {
        height: 84px;
        width: 84px;
        margin: auto;
        z-index: 1051;
        position: fixed;
        left: 0;
        top: 0px;
        right: 0px;
        bottom: 0px;
        .progress-circle-img {
            width: 84px;
            height: 84px;
            background-color: rgba(255, 255, 255, 0.83);
            border-radius: 42px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            img {
                height: 84px;
                width: 84px;
            }
        }
    }
}
.changeCoverPicture {
    display: none !important;
}
.mce-content-body {
    [data-mce-selected='inline-boundary'] {
        background-color: transparent !important;
    }
}
.mce-content-body .mce-item-anchor {
    background-color: transparent !important;
}
ol,
ul {
    li {
        strong {
            font-weight: 700;
        }
    }
}

// REPORT UPDATE TAB CSS
// Report update tab hide on single tab & update off
.report-updates-tab.reportUpdateOff {
    display: none;
}
// vertical center alignment
.report-updates-tab .tab-wrapper li .report-update-name {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    position: relative;
    &.ellipses {
        overflow: hidden;
        white-space: nowrap;
        word-wrap: break-word;
        hyphens: auto;
        display: block;
    }
}
.fund_list {
    cursor: pointer;
}
.panelDiv,
.newFooterLayout,
.addCarouselPanelOuter,
.slideDiv,
.notificationDiv {
    display: none !important;
}
.carousel-control {
    display: inherit !important;
}

/**
 ARROW ISSUE FIX ---> Gallery panel arrow UI disportion fix
*/
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    margin-top: -10px\9;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
#PHOTO_GALLERY .carousel-indicators {
    display: none !important;
}

//Tenant specific css
footer {
    .container-fluid {
        height: auto;
    }
}
.report-fund-list {
    // display: none !important;
}
//Maximum content panel added css
.disabled.maximum-disabled {
    pointer-events: auto;
    cursor: auto;
}

// Fixed editor top bar
#rootComponent {
    .header-action {
        position: fixed;
        z-index: 1000;
        height: 70px;
        border: none;
        .h-inherit {
            height: auto;
            background-color: inherit;
        }
        &:before {
            display: none;
        }
        > div:first-child {
            border: none;
        }
    }
}
#rootHtml {
    padding-top: 70px;
}
.broadCastTemplpatesWrapper {
    margin-top: 35px;
}

//asset editor
.replace-buttonDiv {
    position: absolute !important;
    z-index: 25;
    height: 50px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: none !important;
}
//Hide lock icon for broadcast editor
.broadCastTemplpatesWrapper {
    .inline-textEditor:after {
        display: none !important;
    }
}
#greetingsPanel {
    .inline-textEditor:after {
        display: none;
    }
}

/******* COMMON ELEMENTS CSS *********/

div[tabindex='0']:not([type='button']) {
    outline: none;
}
.web-tab-only {
    display: block;
}
.mob-only {
    display: none;
}
.contact-page-wrapper [sectionid='contact3'],
#greetingsPanel [sectionid='contact3'] {
    p {
        margin: 0;
    }
}
// Set help icon position on editor
#launcher-frame {
    top: inherit !important;
    bottom: 22px !important;
    min-width: 87px !important;
    max-width: 87px !important;
    height: 48px !important;
}
/**************************************************
* REPORT UPDATE GENERIC CSS START
**************************************************/
//variable declaration
$tablet_view: 992/1200;
// $tablet_view: math.div(992, 1200);
$white-color: #ffffff;
@mixin textStyling() {
    color: #4a4a4a;
    font-family: 'Source Sans Pro';
    font-size: 17px;
    font-weight: 700;
    line-height: 19px;
    text-decoration: none;
    display: inline-block;
    min-width: 30px;
}
.report-updates-tab {
    padding: 0 75px;
    .tab-wrapper {
        list-style: none;
        margin: 0;
        display: inline-flex;
        display: -webkit-inline-flex;
        li {
            float: left;
            text-align: center;
            margin-left: 5px;
            padding: 27px 0px 22px;
            min-width: 79px;
            width: inherit;
            cursor: pointer;
            height: 73px;
            &:first-child {
                margin-left: 0;
            }
            .report-update-name {
                @include textStyling();
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                position: relative;
            }
        }
    }
}
/************** mediaquery  tablet View code start********************/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    @mixin textStyling() {
        font-size: 17px * $tablet_view;
        line-height: 19px * $tablet_view;
    }
    .report-updates-tab {
        padding: 0 75px * $tablet_view;
        .tab-wrapper {
            li {
                margin-left: 5px * $tablet_view;
                padding: 27px * $tablet_view 0px 22px * $tablet_view;
                min-width: 79px * $tablet_view;
                height: 73px * $tablet_view;
                .report-update-name {
                    @include textStyling();
                }
            }
        }
    }
}

/************** mediaquery Mobile  View code start********************/
@media only screen and (max-width: 991px) {
    .web-tab-only {
        display: none;
    }

    .mob-only {
        display: block;
    }
    .report-updates-tab {
        width: 100%;
        padding: 0;
        .tab-wrapper {
            display: block;
            max-height: 152px;
            position: absolute;
            top: 44px;
            z-index: 999;
            li {
                height: auto;
                float: none;
                height: 42px;
                margin-left: 0;
                padding: 11px 40px 11px 11px;
                min-width: 100%;
                text-decoration: none;
                box-shadow: none;
                .report-update-name {
                    font-size: 15px;
                    line-height: 17px;
                    display: inline-flex;
                    display: -webkit-inline-flex;
                    justify-content: center;
                    align-items: center;
                    -webkit-align-items: center;
                    text-align: center;
                    text-decoration: none;
                    top: inherit;
                    transform: inherit;
                    -webkit-transform: inherit;
                    position: inherit;
                }
            }
            &.collapsable {
                overflow: auto;
            }
        }
    }
}

// For Report Operational Tab bar Start
.report-update-operational-bar {
    z-index: 999 !important;
    box-shadow: 0px 12px 25px -12px rgba(18, 30, 31, 0.64);
    border: 1px solid #96a3a7;
    background-color: #4f5054;
    .bg-secondary {
        width: auto;
        float: left;
    }
}
// For Report Operational Tab bar End

/**************************************************
* REPORT UPDATE GENERIC CSS END
**************************************************/

.content-panel-editor .slider-collapsable-common {
    display: none !important;
}

.icon-friction-click-error:before {
    display: none !important;
}
//UI distorted in safari
.row:before,
.row:after {
    width: 0 !important;
}
