//
// general.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
    body {
        cursor: pointer;
    }
}
.notification-box {
    position: fixed;
    /* inset: 0; */
    top: 50%;
    left: 50%;
    z-index: 9999;
    max-width: 260px;
    width: 100%;
    transform: translate(-50%, -50%);
    min-height: 100px;
}
.container.offSetHeight {
    box-shadow: none;
}
