//
// topbar.scss
//
$custom-grid-gutter-width-negative: -$grid-gutter-width;
.navbar-custom {
    padding: 0 $grid-gutter-width;
    background-color: $bg-topbar;
    box-shadow: $box-shadow;
    min-height: $topbar-height;
    position: fixed;
    left: $leftbar-width;
    top: 0;
    right: 0;
    z-index: 1039;

    .topbar-left {
        background-color: $bg-topbar;
        height: $topbar-height;
        position: fixed;
        z-index: 1;
        width: $leftbar-width;
        text-align: center;
        top: 0;
        left: 0;
        .logo {
            line-height: $topbar-height;
            i {
                display: none;
            }
        }
    }
    .topbar-menu {
        position: relative;
        z-index: 1;

        li {
            float: left;
            max-height: $topbar-height;

            .show {
                &.nav-link {
                    color: $gray-700;
                }
            }
        }
        .nav-link {
            padding: 0;
            color: $gray-600;
            min-width: 32px;
            display: block;
            text-align: center;
            margin: 0 10px;
            position: relative;
        }
    }

    .app-search {
        position: static;
        overflow-y: hidden;
        padding: calc(#{$topbar-height - 38px} / 2) 5px calc(#{$topbar-height - 38px} / 2) 0;

        .react-select__control,
        .react-select__menu {
            overflow: hidden;
            width: 320px !important;
        }
    }
}

.topbar-dropdown {
    .nav-link {
        line-height: $topbar-height;
    }
}

/* Search */

// topbar-search
.app-search {
    .react-select__control {
        border: none;
        height: $input-height;
        padding-left: 40px;
        background-color: $bg-topbar-search;
        box-shadow: none;
    }
    .react-select__value-container {
        padding: 0;
    }
    .react-select__menu {
        margin-top: 0;
    }
    span.search-icon {
        position: absolute;
        z-index: 9;
        font-size: 20px;
        line-height: 38px;
        left: 10px;
        top: 0;
    }
}

.app-search {
    .form-control {
        border: none;
        height: $input-height;
        padding-left: 40px;
        padding-right: 20px;
        background-color: $bg-topbar-search;
        box-shadow: none;
    }
    span.search-icon {
        position: absolute;
        z-index: 9;
        font-size: 20px;
        line-height: 38px;
        left: 10px;
        top: 0;
    }
    .input-group-text {
        margin-left: 0;
        z-index: 4;
    }
}

/* Notification */
.notification-list {
    margin-left: 0;

    .dropdown-item {
        &:hover {
            color: #6d797e;
        }
    }
    .noti-title {
        background-color: transparent;
        padding: 15px 20px;
        &:hover {
            color: #6d797e;
        }
        h5 {
            cursor: context-menu;
        }
        .text-dark {
            cursor: pointer;
        }
    }

    .noti-icon {
        font-size: 22px;
        vertical-align: middle;
        line-height: $topbar-height;
        &.image-muted {
            opacity: 0.8;
            &:hover,
            &:focus {
                opacity: 1;
            }
        }
        &.notify {
            &:before {
                animation: bell 1s ease-out;
                transform-origin: center top;
            }
        }
        @keyframes bell {
            0% {
                transform: rotate(35deg);
            }
            12% {
                transform: rotate(-30deg);
            }
            25% {
                transform: rotate(25deg);
            }
            37% {
                transform: rotate(-20deg);
            }
            50% {
                transform: rotate(15deg);
            }
            62% {
                transform: rotate(-10deg);
            }
            75% {
                transform: rotate(5deg);
            }
            100% {
                transform: rotate(0);
            }
        }
    }

    .noti-icon-badge {
        display: inline-block;
        position: absolute;
        top: 20px;
        right: 3px;
        font-size: 10px;
        font-weight: bold;
        color: $white;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        background-color: #dd360c;
    }

    .notify-item {
        padding: 10px 20px;
        &:first-child {
            .card-body {
                background-color: #eff4f5;
            }
        }
        &.read-noti {
            background-color: transparent;
            border: 1px solid #eef2f7;
        }
        .notify-icon {
            float: left;
            height: 36px;
            width: 36px;
            line-height: 36px;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            font-size: 30px;
        }

        .card-body {
            padding: 14px;
            cursor: context-menu;
            .noti-item-title {
                margin: 0;
            }
            .noti-close-btn {
                cursor: pointer;
                margin-top: -12px;
                margin-right: -6px;
            }
        }

        .notify-details {
            margin-bottom: 0;
            overflow: hidden;
            margin-left: 45px;
            text-overflow: ellipsis;
            white-space: nowrap;
            b {
                font-weight: 500;
            }
            small {
                display: block;
            }
            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
            }
        }

        .user-msg {
            margin-left: 45px;
            white-space: normal;
            line-height: 16px;
        }
    }
    .topbar-dropdown-menu {
        .notify-item {
            padding: 7px 20px;
        }
    }
}

.profile-dropdown {
    min-width: 170px;

    i,
    span {
        vertical-align: middle;
    }
}

.nav-user {
    //padding: calc(#{$topbar-height - 39px} / 2) 20px calc(#{$topbar-height - 39px} / 2) 57px !important;
    padding: calc(#{$topbar-height - 39px} / 4) 60px calc(#{$topbar-height - 39px} / 4) 35px !important;
    text-align: left !important;
    position: relative;
    background-color: lighten($gray-100, 2.5%);
    border: 1px solid $gray-100;
    border-width: 0 1px;
    min-height: $topbar-height;

    .account-user-avatar {
        position: absolute;
        top: calc(#{$topbar-height - 50px} / 2);
        left: 15px;

        img {
            height: 32px;
            width: 32px;
        }
        i {
            font-size: 32px;
            vertical-align: middle;
        }
    }
    .account-position {
        display: block;
        font-size: 12px;
        margin-top: -3px;
        &.role {
            text-transform: lowercase;
            &:first-line {
                text-transform: capitalize;
            }
        }
    }
    .account-user-name {
        display: block;
        font-weight: $font-weight-semibold;
    }
}

.button-menu-mobile {
    border: none;
    color: $dark;
    height: $topbar-height;
    line-height: $topbar-height;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
    float: left;
    z-index: 1;
    position: relative;
    margin-left: -$grid-gutter-width;

    &.disable-btn {
        display: none;
    }

    @include media-breakpoint-down(md) {
        margin: 0 !important;
    }
}

// .page-title-box {
//   opacity: 1;
//   transform: translateY(0px);
//   transition: transform 0.1s ease;
// }

.page-title-box.sticky-title {
    position: sticky;
}

.page-title-box.fixed-title {
    position: fixed;
    top: 14px;
    margin-left: 25px;
    z-index: 1039;
    transform: translateY(0);
    width: auto;
    pointer-events: all;
    .page-title-right {
        float: right;
        margin-top: 18px;
        left: 15px;
        position: absolute;
        font-size: 13px;
        width: max-content;
        height: 40px;
        z-index: 1040;
    }

    .page-title {
        bottom: 24px;
        left: 15px;
        position: relative;
        font-size: 15px;
        height: auto;
        .icon-large {
            font-size: large;
        }
    }

    &.visible {
        transform: translateY(0px);
        pointer-events: all;
    }
}

.custom-title-position {
    position: fixed !important;
    top: 7px !important;
    z-index: 1040;
    .icon-large {
        font-size: large !important;
    }
}

/* Smooth transition for visibility */
.page-title-box,
.button-menu-mobile {
    transition: none;
}

body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .content-page {
    .page-title-box.fixed-title {
        margin-left: 25px;
    }
}

body[data-leftbar-compact-mode='fixed'] {
    .page-title-box,
    .button-menu-mobile {
        transition: none;
    }
}

[data-keep-enlarged='true'] {
    .navbar-custom {
        padding-left: 0;
    }

    .button-menu-mobile {
        margin: 0;

        &.disable-btn {
            display: inline-block;
        }
    }
}

@media (max-width: 600px) {
    .navbar-custom {
        .topbar-menu {
            position: initial;
        }

        .dropdown {
            position: static;

            .dropdown-menu {
                left: 10px !important;
                right: 10px !important;
            }
        }
    }
}

@media (max-width: 1200px) {
    .navbar-custom {
        right: 0;

        .app-search {
            display: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .navbar-custom {
        left: 0 !important;
        padding: 0;
    }
    .button-menu-mobile {
        &.disable-btn {
            display: inline-block;
        }
    }
    .nav-user {
        padding: 17px 5px 17px 57px !important;
        padding: 17px 25px 17px 55px !important;

        .account-position,
        .account-user-name {
            display: none;
        }
    }
}

@media (max-width: 375px) {
    .navbar-custom {
        .topbar-menu {
            .nav-link {
                margin: 0 6px;
            }
        }

        &.topnav-navbar {
            .container-fluid {
                padding-right: 12px;
                padding-left: 12px;
            }
            .navbar-toggle {
                margin: 27px 3px;
            }
            .button-menu-mobile {
                width: auto;
            }
        }
    }
}

// Scrollable Layout

@include media-breakpoint-up(lg) {
    body[data-leftbar-compact-mode='scrollable']:not([data-layout='topnav']) {
        .navbar-custom {
            position: absolute;
        }
    }
}

// Topbar Dark
.topnav-navbar-dark {
    background-color: $bg-topbar-dark;

    .nav-user {
        background-color: lighten($bg-topbar-dark, 5%);
        border: 1px solid lighten($bg-topbar-dark, 7.5%);
    }

    .topbar-menu {
        li {
            .show {
                &.nav-link {
                    color: $white !important;
                }
            }
        }
    }

    .app-search {
        .react-select__control {
            background-color: $bg-topbar-dark-search;
            color: $white;
        }
        .react-select__input {
            color: $white;
        }
        span,
        .react-select__placeholder {
            color: $gray-600;
        }
    }

    .navbar-toggle {
        span {
            background-color: rgba($white, 0.8) !important;
        }
    }
}

body[data-layout-mode='boxed'] {
    .navbar-custom {
        position: relative;
        left: 0 !important;
        margin: -$topbar-height calc($custom-grid-gutter-width-negative / 2) 0;
    }

    &[data-layout='topnav'] {
        .navbar-custom {
            margin: 0;
        }
    }
}

// topbar dropdown arrow alignment
.custom-down-arrow-align {
    position: absolute;
    top: 23px;
    font-size: 18px;
    right: 25px;
    opacity: 0.8;
}
@include media-breakpoint-between(xs, md) {
    .custom-down-arrow-align {
        font-size: 28px;
        top: 15px;
    }
}
