//
// custom-forms.scss
//

@each $color, $value in $theme-colors {
    .form-checkbox-#{$color},
    .form-radio-#{$color} {
        .form-check-input {
            &:checked {
                background-color: $value;
                border-color: $value;
            }
        }
    }
}

.content-alignment {
    position: relative;
    top: 4px;
    span {
        span {
            margin-top: 1px;
        }
    }
    .form-switch {
        // padding-top: 3px;
    }
}
