/*** INLINE EDITOR CSS ***/
.content-panel-editor .centerally-managed-cp {
    .assetseditor.inline-textEditor.common-right-lock:hover:after,
    .assetseditor.inline-textEditor:hover:after {
        display: none;
    }
}
.content-panel-editor .INTERACTION_PARENT {
    .assetseditor.inline-textEditor.common-right-lock:hover:after,
    .assetseditor.inline-textEditor:hover:after {
        display: none;
    }
}
.assetseditor.inline-textEditor {
    min-height: 20px;
    min-width: 30px;
    position: relative;
    overflow-wrap: break-word;
    word-wrap: break-word;
    //Unlock condition
    &:hover,
    &:focus-visible {
        outline: 2px solid $primary !important;
    }
    &.common-right-lock:hover:after,
    &:hover:after {
        background-color: $primary;
        display: block;
        z-index: 10;
    }
    &:after {
        color: $white;
        position: absolute;
        right: -2px;
        cursor: pointer;
        top: 22px;
        width: 25px;
        height: 25px;
        padding: 7px;
        padding-left: 9px;
        font-size: 12px;
        display: none;
        font-style: normal;
    }
    &.common-right-lock:after,
    &:after {
        top: -2px;
        right: -25px;
    }
    //Locked condition
    &.non-editable {
        &:hover {
            background-color: transparent !important;
            outline: 2px solid $danger !important;
        }
        &.common-right-lock:hover:after,
        &:hover:after {
            background-color: $danger;
            display: block;
        }
    }
}
//editor section icons
.inline-textEditor:after {
    content: '\f09c';
    // font-family: FontAwesome;
    line-height: 1;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}
.common-right-lock.lock:after,
.lock:after {
    content: '\f023';
}
.replace-buttonDiv:hover {
    display: block !important;
}

//Asset replacer
.asset-replacer,
.operational-bar {
    &.last-element {
        div.bg-secondary {
            padding: 10px;
        }
        &.btn-group > .btn:not(:first-child),
        &.btn-group > .btn-group:not(:first-child) {
            margin-left: 0;
        }
    }
    border: 1px solid $white;
    box-shadow: 0px 2px 6px 0px rgb(79 80 84 / 50%);
    border-radius: 4px;
    visibility: hidden;
    position: absolute;
    z-index: 1;
    .bg-secondary {
        background-color: #4f5054 !important;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }
    .btn-secondary {
        background-color: #4f5054;
        border-color: #4f5054;
        &:hover {
            background-color: #001f24;
            border-color: #001f24;
        }
        &.disabled {
            background-color: #6b6d71;
            border-color: #6b6d71;
            color: #c4c6c6;
            opacity: 1;
        }
        &:last-child {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
}

/* 
* Block click event on link --> (Can be removed if not required)
* This should work only for editor
* This need to check for all and after complete integration is done for tinymce
*/
.assetseditor.inline-textEditor {
    a[href],
    strong,
    em,
    span[data-mce-style],
    [contenteditable='false'] {
        // pointer-events: none;
    }
    &.lock.non-editable {
        [contenteditable='false'] {
            pointer-events: none;
            cursor: none;
        }
    }
}

//Cursor not allow when locked
.non-editable {
    cursor: not-allowed !important;
}

.report-fund-list {
    // display: none !important;
}
.editor {
    .lock.non-editable a[rel='noopener'] {
        pointer-events: none;
        cursor: none;
    }
}
