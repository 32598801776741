.modal.show .modal-dialog.preview {
    max-width: 1265px;
    transform: scale(0.8) translate(0, 0);
    -webkit-transform: scale(0.8) translate(0, 0);
    margin: 0 auto;
}
.pb-page-container-preview {
    zoom: 1.84;
    transform: scale(1.84) translate(0, 0);
    -webkit-transform: scale(1.84) translate(0, 0);
    width: fit-content;
}
.previewOnly {
    max-height: 765px;
    overflow-y: scroll;
    padding: 0;
    div[cpid] {
        // transform: translateX(-50%);
        // -webkit-transform: translateX(-50%);
        // margin-left: 50%;
        // zoom: 0.9;
        // max-height: 715px;
        // overflow-y: scroll;
        .assetseditor,
        .panelHeader,
        .inline-textEditor,
        .carousel-inner,
        .assetseditor:hover {
            pointer-events: none !important;
        }
    }
    &.hide {
        display: none;
    }
}

.previewOnly {
    div[cpid].photo-gallery-primary .carousel-inner {
        pointer-events: unset !important;
    }
}

/********** CSS FOR PREVIEW NOT AVAILABLE **********/
.previewOnly {
    .noPreviewParent {
        box-shadow: none;
        .carousel-inner {
            height: 595px !important;
            .carousel-item.active {
                display: none;
            }
        }
    }
}
.previewOnly.noPreviewParent {
    padding-bottom: 75px;
    padding-top: 50px;
}
.noPreviewAvailable {
    font-size: 24px;
    font-family: Roboto-Bold;
    text-align: center;
    color: #9ca8ab;
    margin-top: 23%;
    &:before {
        content: url('https://old.asset.common.ovrture.com/common/preview-not-available.png');
        display: inherit;
        padding-bottom: 17px;
    }
}

.tox-edit-area iframe {
    background-color: transparent !important;
}
